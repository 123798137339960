import styled from "styled-components";

const ButtonBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    flex: 1;
  }
`;

export default ButtonBar;