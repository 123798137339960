import styled from "styled-components";

const Input = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 0.5rem;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  font-family: inherit;
`;

export default Input;
