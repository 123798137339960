import { useState } from "react";
import styled from "styled-components";
import ButtonBar from "../ButtonBar";
import Input from "../Input";
import LinkButton from "../LinkButton";
import Logo from "../Logo";

const Author = styled.span`
  font-size: 0.75rem;
  opacity: 0.75;
  margin: 1rem;
`;

const Anchor = styled.a`
  font-weight: bold;
`;

const ConfigurationWrapper = styled.div`
  width: 300px;

  & > * {
    margin-bottom: 1rem;
  }
`;

const HomePage = () => {
  const [numRounds, setNumRounds] = useState("5");
  const [category, setCategory] = useState("bulge");

  return (
    <>
      <Logo />

      <ConfigurationWrapper>
        <div>
          <span>Number of Rounds</span>
          <select
            value={numRounds}
            onChange={(e) => setNumRounds(e.target.value)}
            style={{ margin: "0 0.5rem 0.5rem 0.5rem" }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
          </select>
        </div>

        <div>
          <span>Category</span>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            style={{ margin: "0 0.5rem 0.5rem 0.5rem" }}
          >
            <option value="bulge">Bulges</option>
            <option value="feet">Feet</option>
            <option value="all">All</option>
          </select>
        </div>

        <ButtonBar style={{ gap: "0.5rem" }}>
          <LinkButton to={`/game/1?rounds=${numRounds}&category=${category}`}>1 Player</LinkButton>
          <LinkButton to={`/game/2?rounds=${numRounds}&category=${category}`}>2 Players</LinkButton>
        </ButtonBar>
      </ConfigurationWrapper>

      <Author>
        Built by{" "}
        <Anchor href="https://twitter.com/fleetfoxx" target="_blank">
          Fleetfoxx
        </Anchor>
      </Author>
    </>
  );
};

export default HomePage;
