import styled from "styled-components";
import logo from "../logo.png";

const Image = styled.img`
  width: 300px;
`;

const Logo = () => {
  return <Image src={logo} alt="Whose BULGE is it Anyway?" />;
};

export default Logo;
