import { InputHTMLAttributes, useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import Input from "./Input";

const Wrapper = styled.div`
  display: flex;

  & > input {
    flex: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > button {
    flex: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

type Props = InputHTMLAttributes<HTMLInputElement>;

const SecretInput = (props: Props) => {
  const [hidden, setHidden] = useState(true);

  return (
    <Wrapper>
      <Input {...props} type={hidden ? "password" : "text"} />
      <Button onClick={() => setHidden((prev) => !prev)}>
        {hidden ? "🙈" : "🙉"}
      </Button>
    </Wrapper>
  );
};

export default SecretInput;
