import { css } from "styled-components";

const buttonStyles = css`
  background: #c74b50;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  transition: filter 0.1s ease;
  white-space: nowrap;
  font-weight: bolder;
  font-size: 1rem;
  font-family: inherit;

  &:hover:not(:disabled) {
    filter: brightness(75%);
  }

  &:disabled {
    filter: saturate(25%) brightness(125%);
  }
`;

export default buttonStyles;