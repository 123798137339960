import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Bulge, getBulges } from "../../bulges";
import Button from "../Button";
import Input from "../Input";
import LinkButton from "../LinkButton";
import Logo from "../Logo";
import StyledLink from "../StyledLink";

const Scoreboard = styled.div`
  display: flex;
  justify-content: center;
`;

const Score = styled.span`
  padding: 1rem;
`;

const Image = styled.img`
  width: 300px;
  border-radius: 5px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.3);
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Answer = styled.span`
  background: #d49b54;
  margin-top: -1rem;
  padding: 1rem 1rem 0.5rem 1rem;
  display: block;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: bold;
  font-size: 1.5rem;
`;

const Credit = styled.span`
  font-size: 0.75rem;
  opacity: 0.75;
`;

const Result = styled.span`
  font-weight: bold;
  margin: 1rem 0;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

const HintList = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 0.5rem;
  }
`;

const Hint = styled.div`
  background: #d49b54;
  border-radius: 5px;
  color: black;
  padding: 0.5rem;
  font-size: 0.9rem;
  text-align: left;
`;

const GamePage = () => {
  const [searchParams] = useSearchParams();
  const numRounds = searchParams.get("rounds");
  const category = searchParams.get("category");

  const [rounds, setRounds] = useState<Bulge[]>();
  const [currentRound, setCurrentRound] = useState(0);
  const [hintsRequested, setHintsRequested] = useState(0);
  const [hasGuessed, setHasGuessed] = useState(false);
  const [isGuessCorrect, setIsGuessCorrect] = useState(false);
  const [player1Score, setPlayer1Score] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [guessValue, setGuessValue] = useState("");

  useEffect(() => {
    const rounds = parseInt(numRounds!);
    setRounds(getBulges(rounds, category!));
  }, [numRounds, category]);

  const handleGuess = () => {
    // nothing entered
    if (!guessValue) return;

    setHasGuessed(true);

    const result = rounds![currentRound].answers.some(
      (answer) => answer.toLowerCase() === guessValue?.toLowerCase()
    );

    if (result === true) {
      setPlayer1Score((prev) => prev + (3 - hintsRequested));
    }

    setIsGuessCorrect(result);
  };

  const handleGiveUp = () => {
    setHasGuessed(true);
    setIsGuessCorrect(false);
  };

  const handleNextRound = () => {
    setCurrentRound((prev) => prev + 1);
    setHintsRequested(0);
    setHasGuessed(false);
    setIsGuessCorrect(false);
    setGuessValue("");
  };

  if (!rounds) {
    return <span>Loading...</span>;
  }

  if (showResults) {
    return (
      <>
        <Logo />

        <h2>Score: {player1Score}</h2>

        <LinkButton to="/">New game</LinkButton>
      </>
    );
  }

  return (
    <>
      <h1>
        Round {currentRound + 1}/{numRounds}
      </h1>
      <StyledLink to="/">return home</StyledLink>
      <Scoreboard>
        <Score>Score: {player1Score}</Score>
      </Scoreboard>

      {hasGuessed ? (
        <>
          <ImageWrapper>
            <Image src={rounds[currentRound].answerPath} />
            <Answer>{rounds[currentRound].answers[0]}</Answer>
          </ImageWrapper>

          {rounds[currentRound].credit && (
            <Credit>credit: {rounds[currentRound].credit}</Credit>
          )}

          {guessValue && (
            <>
              <span style={{ marginTop: "1rem" }}>
                You guessed "{guessValue}"
              </span>

              <Result>
                {isGuessCorrect
                  ? `Correct! +${3 - hintsRequested} point${
                      3 - hintsRequested === 1 ? "" : "s"
                    }`
                  : "Wrong..."}
              </Result>
            </>
          )}
        </>
      ) : (
        <Image src={rounds[currentRound].bulgePath} />
      )}

      <Controls>
        {hasGuessed ? (
          <>
            {currentRound < rounds.length - 1 ? (
              <Button onClick={handleNextRound}>Next Round</Button>
            ) : (
              <Button onClick={() => setShowResults(true)}>See results!</Button>
            )}
          </>
        ) : (
          <>
            <Input
              type="text"
              value={guessValue}
              onChange={(e) => setGuessValue(e.target.value)}
              placeholder="Who is this?"
              autoFocus
            />

            <Button onClick={handleGuess} disabled={guessValue === ""}>
              Lock in your guess
            </Button>

            {hintsRequested < rounds[currentRound].hints.length ? (
              <Button onClick={() => setHintsRequested((prev) => prev + 1)}>
                Request hint (
                {rounds[currentRound].hints.length - hintsRequested})
              </Button>
            ) : (
              <Button onClick={handleGiveUp}>Give up</Button>
            )}
          </>
        )}
      </Controls>

      {!hasGuessed && (
        <HintList style={{ marginTop: "1rem" }}>
          {[...Array(hintsRequested)].map((_, i) => (
            <Hint key={`${currentRound}-${i}`}>
              <strong>💡 Hint #{i + 1}:</strong> {rounds[currentRound].hints[i]}
            </Hint>
          ))}
        </HintList>
      )}
    </>
  );
};

export default GamePage;
