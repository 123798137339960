export const Categories = {
  Bulge: "bulge",
  Feet: "feet",
  Chest: "chest",
  All: "all",
};

export type Bulge = {
  answerPath: string;
  bulgePath: string;
  hints: string[];
  answers: string[];
  credit?: string;
  category: string;
};

const bulges: Bulge[] = [
  {
    bulgePath: "/bulges/luigi-bulge.jpg",
    answerPath: "/bulges/luigi-full.jpg",
    hints: ["Green brother", "Mansion"],
    answers: ["Luigi"],
    credit: "u/rcdr_90",
    category: Categories.Bulge,
  },
  {
    bulgePath: "/bulges/cloud-bulge.jpg",
    answerPath: "/bulges/cloud-full.jpg",
    hints: ["Big sword", "Fantasy"],
    answers: ["Cloud Strife", "Cloud"],
    category: Categories.Bulge,
  },
  {
    bulgePath: "/bulges/wario-bulge.jpg",
    answerPath: "/bulges/wario-full.jpg",
    hints: ["Farts", "Ballsack chin"],
    answers: ["Wario"],
    category: Categories.Bulge,
  },
  {
    bulgePath: "/bulges/sidon-bulge.png",
    answerPath: "/bulges/sidon-full.png",
    hints: [
      "Has a tendency to get you wet",
      "The biggest bulge in all of Zora",
    ],
    answers: ["Prince Sidon", "Sidon"],
    category: Categories.Bulge,
  },
  {
    bulgePath: "/bulges/kratos-bulge.png",
    answerPath: "/bulges/kratos-full.png",
    hints: ["Odin's raven!", "#1 dad"],
    answers: ["Kratos", "Lord Kratos"],
    category: Categories.Bulge,
  },
  {
    bulgePath: "/bulges/snake-bulge.png",
    answerPath: "/bulges/snake-full.png",
    hints: [
      "A solid bulge if I do say so myself.",
      "Maybe not an anaconda, but...",
    ],
    answers: ["Solid Snake", "Snake"],
    category: Categories.Bulge,
  },
  {
    bulgePath: "/bulges/drake-bulge.png",
    answerPath: "/bulges/drake-full.png",
    hints: [
      "Is that treasure in your pocket or are you just happy to see me?",
      "Sir Francis ain't got nothing on this guy.",
    ],
    answers: ["Nathan Drake", "Nate Drake", "Nathan Morgan", "Drake"],
    category: Categories.Bulge,
  },
  {
    bulgePath: "/bulges/markus-fenix-bulge.jpg",
    answerPath: "/bulges/markus-fenix-full.jpg",
    hints: ["Nice COG.", "Gears aren't the only thing he's grinding."],
    answers: ["Marcus Fenix", "Marcus Michael Fenix", "Marcus", "Fenix"],
    category: Categories.Bulge,
  },
  {
    bulgePath: "/bulges/mewtwo-bulge.jpg",
    answerPath: "/bulges/mewtwo-full.jpg",
    hints: [
      "Its brain isn't the only thing bulging.",
      "Bulge game so strong you'd think it was created in a lab.",
    ],
    answers: ["Mewtwo"],
    category: Categories.Bulge,
  },
  {
    bulgePath: "/bulges/samus-bulge.jpg",
    answerPath: "/bulges/samus-full.jpg",
    hints: [
      "They could SMASH you in a fight.",
      "Who wouldn't take a ride on that morph ball?",
    ],
    answers: ["Samus Aran", "Samus", "Zero Suit Samus"],
    category: Categories.Bulge,
  },
  {
    bulgePath: "/bulges/ratchet-bulge.jpg",
    answerPath: "/bulges/ratchet-full.jpg",
    hints: [
      "Known for torquing loose screws.",
      "I wonder if their robot friend has a vibrate mode.",
    ],
    answers: ["Ratchet"],
    category: Categories.Bulge,
  },
  {
    bulgePath: "/bulges/clank-bulge.jpg",
    answerPath: "/bulges/clank-full.jpg",
    hints: [
      "A heli-pack isn't the ony thing they're packing.",
      "Best friends with the last remaining Lombax in the universe.",
    ],
    answers: ["Clank", "XJ-0461", "B5429671"],
    category: Categories.Bulge,
  },

  // FEET
  {
    bulgePath: "/feet/bayonetta-feet.png",
    answerPath: "/feet/bayonetta-full.png",
    hints: [
      "Let's dance, boys!",
      "These witchy grippers can put a hole through you.",
    ],
    answers: ["Bayonetta"],
    category: Categories.Feet,
  },
  {
    bulgePath: "/feet/chun-li-feet.png",
    answerPath: "/feet/chun-li-full.png",
    hints: [
      "Want to see my Kung-Fu?",
      "You better hope you don't see these puppies flying at you on the street.",
    ],
    answers: ["Chun-Li"],
    category: Categories.Feet,
  },
  {
    bulgePath: "/feet/cortana-feet.png",
    answerPath: "/feet/cortana-full.png",
    hints: [
      "Knows a lot about rings, but not of the toe variety.",
      "No shoes required for this software.",
    ],
    answers: ["Cortana", "CTN 0452-9"],
    category: Categories.Feet,
  },
  {
    bulgePath: "/feet/dk-feet.png",
    answerPath: "/feet/dk-full.png",
    hints: [
      "This king of swing can grip just about anything with these grippers.",
      "I wonder if they can drive a go-kart with those toes?",
    ],
    answers: ["Donkey Kong"],
    category: Categories.Feet,
  },
  {
    bulgePath: "/feet/dva-feet.png",
    answerPath: "/feet/dva-full.jpg",
    hints: [
      "Fans got a kick out of her waveracer skin.",
      "I wonder if her mech includes a foot massager?",
    ],
    answers: ["D.Va"],
    category: Categories.Feet,
  },
  {
    bulgePath: "/feet/kirby-feet.png",
    answerPath: "/feet/kirby-full.png",
    hints: [
      "This pink puff has the softest feet around.",
      "What would happen if they inhaled a shoe?",
    ],
    answers: ["Kirby"],
    category: Categories.Feet,
  },
  {
    bulgePath: "/feet/machoke-feet.png",
    answerPath: "/feet/machoke-full.png",
    hints: [
      "There's nothing creepy about cuddling this human-like fighter.",
      "When it evolves, it gains a couple arms and looses a few toes.",
    ],
    answers: ["Machoke"],
    category: Categories.Feet,
  },
  {
    bulgePath: "/feet/nessa-feet.png",
    answerPath: "/feet/nessa-full.jpg",
    hints: [
      "Don't worry, these grippers are cannonically 21 years old.",
      "This gym leader is an excellent swimmer.",
    ],
    answers: ["Nessa"],
    category: Categories.Feet,
  },
  {
    bulgePath: "/feet/peach-feet.png",
    answerPath: "/feet/peach-full.png",
    hints: [
      "Usually covered by a long dress, these puppies occasionally get some time in the sun.",
      "These feet are great for stomping goombas.",
    ],
    answers: [
      "Princess Peach",
      "Peach",
      "Princess Peach Toadstool",
      "Princess Toadstool",
    ],
    category: Categories.Feet,
  },
  {
    bulgePath: "/feet/samus-feet.png",
    answerPath: "/feet/samus-full.jpg",
    hints: [
      "This bounty hunter tends to keep her feet inside her suit.",
      "Where do her feet go when she's in ball form?",
    ],
    answers: ["Samus Aran", "Samus"],
    category: Categories.Feet,
  },
  {
    bulgePath: "/feet/sonic-feet.png",
    answerPath: "/feet/sonic-full.png",
    hints: [
      "These piggies are typically found running around inside a pair of red sneakers.",
      "Can you imagine how bad these grippers must stink after running around so much?",
    ],
    answers: ["Sonic the Hedgehog", "Sonic"],
    category: Categories.Feet,
  },
  {
    bulgePath: "/feet/steve-feet.png",
    answerPath: "/feet/steve-full.png",
    hints: [
      "This character has no trouble keeping the creepers away with these pixelated feet pics.",
      "You'll want to protect these toes with some diamond boots.",
    ],
    answers: ["Steve", "Herobrine"],
    category: Categories.Feet,
  },
  {
    bulgePath: "/feet/zelda-feet.png",
    answerPath: "/feet/zelda-full.png",
    hints: [
      "Don't ask for a link to this princess's feet pics.",
      "Can you imagine how sore these grippers must be after walking all over Hyrule?",
    ],
    answers: ["Princess Zelda", "Zelda"],
    category: Categories.Feet,
  },
];

export const getBulges = (size: number, category: string): Bulge[] => {
  size = Math.min(size, bulges.length);

  const bulgesCopy = [...bulges].filter(
    (q) => category === Categories.All || q.category === category
  );
  const pickedBulges: Bulge[] = [];
  let random = 0;
  let pickedBulge = null;

  // shuffle
  for (let i = 0; i < size; i++) {
    random = Math.floor(Math.random() * bulgesCopy.length);
    pickedBulge = bulgesCopy.splice(random, 1)[0];
    pickedBulges.push(pickedBulge);
  }

  console.log(pickedBulges);

  return pickedBulges;
};

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

export default bulges;
