import styled from "styled-components";
import buttonStyles from "./buttonStyles";

const Button = styled.button`
  ${buttonStyles}
  border: 0;
  color: inherit;
  cursor: pointer;
`;

export default Button;
