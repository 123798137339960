/**
 * TODO
 *
 * - BUG: points are awarded at lock-in, not reveal (2 player)
 * - BUG: points are incorrectly deducted if hints are used after lock-in (2 player)
 *
 */

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Bulge, getBulges } from "../../bulges";
import Button from "../Button";
import Input from "../Input";
import LinkButton from "../LinkButton";
import Logo from "../Logo";
import SecretInput from "../SecretInput";
import StyledLink from "../StyledLink";

const Scoreboard = styled.div`
  display: flex;
  justify-content: center;
`;

const Score = styled.span`
  padding: 1rem;
`;

const Image = styled.img`
  width: 300px;
  border-radius: 5px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.3);
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Answer = styled.span`
  background: #d49b54;
  margin-top: -1rem;
  padding: 1rem 1rem 0.5rem 1rem;
  display: block;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: bold;
  font-size: 1.5rem;
`;

const Credit = styled.span`
  font-size: 0.75rem;
  opacity: 0.75;
`;

const Result = styled.span`
  font-weight: bold;
  margin: 1rem 0;
`;

const ResultWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const LockedIn = styled.span`
  background: #d49b54;
  border-radius: 5px;
  padding: 0.5rem 1rem;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  flex: 1;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

const ControlWrapper = styled.div`
  display: flex;
  gap: 1rem;
  width: 600px;
`;

const HintList = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 0.5rem;
  }
`;

const Hint = styled.div`
  background: #d49b54;
  border-radius: 5px;
  color: black;
  padding: 0.5rem;
  font-size: 0.9rem;
  text-align: left;
`;

const TwoPlayerGamePage = () => {
  const [searchParams] = useSearchParams();
  const numRounds = searchParams.get("rounds");
  const category = searchParams.get("category");

  const [rounds, setRounds] = useState<Bulge[]>();
  const [currentRound, setCurrentRound] = useState(0);
  const [hintsRequested, setHintsRequested] = useState(0);
  const [showRoundResults, setShowRoundResults] = useState(false);
  const [showGameResults, setShowGameResults] = useState(false);

  const [player1Score, setPlayer1Score] = useState(0);
  const [player1PossiblePoints, setPlayer1PossiblePoints] = useState(0);
  const [hasPlayer1LockedIn, setHasPlayer1LockedIn] = useState(false);
  const [isPlayer1GuessCorrect, setIsPlayer1GuessCorrect] = useState(false);
  const [player1GuessValue, setPlayer1GuessValue] = useState("");

  const [player2Score, setPlayer2Score] = useState(0);
  const [player2PossiblePoints, setPlayer2PossiblePoints] = useState(0);
  const [hasPlayer2LockedIn, setHasPlayer2LockedIn] = useState(false);
  const [isPlayer2GuessCorrect, setIsPlayer2GuessCorrect] = useState(false);
  const [player2GuessValue, setPlayer2GuessValue] = useState("");

  useEffect(() => {
    const rounds = parseInt(numRounds!);
    setRounds(getBulges(rounds, category!));
  }, [numRounds, category]);

  const handlePlayer1LockIn = () => {
    // nothing entered
    if (!player1GuessValue) return;

    setHasPlayer1LockedIn(true);
    setPlayer1PossiblePoints(3 - hintsRequested);
  };

  const handlePlayer2LockIn = () => {
    // nothing entered
    if (!player2GuessValue) return;

    setHasPlayer2LockedIn(true);
    setPlayer2PossiblePoints(3 - hintsRequested);
  };

  const handleNextRound = () => {
    setCurrentRound((prev) => prev + 1);
    setHintsRequested(0);
    setShowRoundResults(false);

    setHasPlayer1LockedIn(false);
    setHasPlayer2LockedIn(false);

    setIsPlayer1GuessCorrect(false);
    setIsPlayer2GuessCorrect(false);

    setPlayer1GuessValue("");
    setPlayer2GuessValue("");

    setPlayer1PossiblePoints(0);
    setPlayer2PossiblePoints(0);
  };

  const handleShowRoundResults = () => {
    setShowRoundResults(true);

    // caluclate player 1's round score
    const player1Result = rounds![currentRound].answers.some(
      (answer) => answer.toLowerCase() === player1GuessValue?.toLowerCase()
    );

    if (player1Result === true) {
      setPlayer1Score((prev) => prev + player1PossiblePoints);
    }

    setIsPlayer1GuessCorrect(player1Result);

    // caluclate player 2's round score
    const player2Result = rounds![currentRound].answers.some(
      (answer) => answer.toLowerCase() === player2GuessValue?.toLowerCase()
    );

    if (player2Result === true) {
      setPlayer2Score((prev) => prev + player2PossiblePoints);
    }

    setIsPlayer2GuessCorrect(player2Result);
  };

  if (!rounds) {
    return <span>Loading...</span>;
  }

  if (showGameResults) {
    return (
      <>
        <Logo />

        <Scoreboard>
          <Score>Player 1: {player1Score}</Score>
          <Score>Player 2: {player2Score}</Score>
        </Scoreboard>

        <h2>
          {player1Score > player2Score
            ? "Player 1 Wins!"
            : player2Score > player1Score
            ? "Player 2 Wins!"
            : "It's a Tie!"}
        </h2>

        <LinkButton to="/">New game</LinkButton>
      </>
    );
  }

  return (
    <>
      <h1>
        Round {currentRound + 1}/{numRounds}
      </h1>
      <StyledLink to="/">return home</StyledLink>
      <Scoreboard>
        <Score>Player 1: {player1Score}</Score>
        <Score>Player 2: {player2Score}</Score>
      </Scoreboard>

      {showRoundResults ? (
        <>
          <ImageWrapper>
            <Image src={rounds[currentRound].answerPath} />
            <Answer>{rounds[currentRound].answers[0]}</Answer>
          </ImageWrapper>

          {rounds[currentRound].credit && (
            <Credit>credit: {rounds[currentRound].credit}</Credit>
          )}

          <ResultWrapper>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ marginTop: "1rem" }}>
                Player 1 guessed "{player1GuessValue}"
              </span>

              <Result>
                {isPlayer1GuessCorrect
                  ? `Correct! +${player1PossiblePoints} point${
                      player1PossiblePoints === 1 ? "" : "s"
                    }`
                  : "Wrong..."}
              </Result>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ marginTop: "1rem" }}>
                Player 2 guessed "{player2GuessValue}"
              </span>

              <Result>
                {isPlayer2GuessCorrect
                  ? `Correct! +${player2PossiblePoints} point${
                      player2PossiblePoints === 1 ? "" : "s"
                    }`
                  : "Wrong..."}
              </Result>
            </div>
          </ResultWrapper>

          {currentRound < rounds.length - 1 ? (
            <Button onClick={handleNextRound}>Next Round</Button>
          ) : (
            <Button onClick={() => setShowGameResults(true)}>
              See results!
            </Button>
          )}
        </>
      ) : (
        <>
          <Image src={rounds[currentRound].bulgePath} />

          <ControlWrapper>
            <Controls>
              <span>Player 1</span>
              {hasPlayer1LockedIn ? (
                <>
                  <LockedIn>Locked in</LockedIn>
                </>
              ) : (
                <>
                  <SecretInput
                    value={player1GuessValue}
                    onChange={(e) => setPlayer1GuessValue(e.target.value)}
                    placeholder="Who is this?"
                  />

                  <Button
                    onClick={handlePlayer1LockIn}
                    disabled={player1GuessValue === ""}
                  >
                    Lock in your guess
                  </Button>
                </>
              )}
            </Controls>

            <Controls>
              <span>Player 2</span>
              {hasPlayer2LockedIn ? (
                <>
                  <LockedIn>Locked in</LockedIn>
                </>
              ) : (
                <>
                  <SecretInput
                    value={player2GuessValue}
                    onChange={(e) => setPlayer2GuessValue(e.target.value)}
                    placeholder="Who is this?"
                  />

                  <Button
                    onClick={handlePlayer2LockIn}
                    disabled={player2GuessValue === ""}
                  >
                    Lock in your guess
                  </Button>
                </>
              )}
            </Controls>
          </ControlWrapper>

          {hasPlayer1LockedIn && hasPlayer2LockedIn ? (
            <Button onClick={handleShowRoundResults}>Show results</Button>
          ) : (
            <Button
              onClick={() => setHintsRequested((prev) => prev + 1)}
              disabled={hintsRequested >= rounds[currentRound].hints.length}
            >
              Request hint ({rounds[currentRound].hints.length - hintsRequested}
              )
            </Button>
          )}
        </>
      )}

      <HintList style={{ marginTop: "1rem", width: 300 }}>
        {[...Array(hintsRequested)].map((_, i) => (
          <Hint key={`${currentRound}-${i}`}>
            <strong>💡 Hint #{i + 1}:</strong> {rounds[currentRound].hints[i]}
          </Hint>
        ))}
      </HintList>
    </>
  );
};

export default TwoPlayerGamePage;
