import { Outlet } from "react-router-dom";
import styled from "styled-components";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
`;

const App = () => {
  return (
    <Layout id="App">
      <Outlet />
    </Layout>
  );
};

export default App;
